const namespace = ".registration"

function removeRegistrationErrors() {
  const fields = ["password", "email"]

  fields.forEach(key => {
    const field = document.querySelector(`${namespace} #user_${key}`)
    if (field) {
      const removeError = e => {
        const datasetKey = `show${key.charAt(0).toUpperCase()}${key.slice(1)}Error`
        const target = e.currentTarget
        const fieldWrapper = document.querySelector(`${namespace} #${key}-field-wrapper`)
        const showErrorList = document.querySelector(`${namespace} #show-${key}-error-list`)
        const showError = JSON.parse(target.dataset[datasetKey])

        if (showError && target.value.length > 0) {
          fieldWrapper.classList.toggle("parsley-error")
          showErrorList && showErrorList.remove()
          target.dataset[datasetKey] = false
        }
      }
      field.addEventListener("keyup", removeError, true)
    }
  })
}

export default removeRegistrationErrors
